import { removeAgencyCookies, removeAgencySearchParams } from '@/ducks/fm/setters';
import { agentDataClear } from '@/ducks/fm/slice';
import { type AppDispatch } from '@/store';

import { verifyCurrentCurrencyCode } from './actions';

export const resetFmFlow = () => (dispatch: AppDispatch) => {
  removeAgencyCookies();
  dispatch(agentDataClear());
  dispatch(verifyCurrentCurrencyCode());
  removeAgencySearchParams();
};
